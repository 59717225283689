<template>
	<fieldset>
		<div class="text-primary text-md bold uppercase mb-2">
			Anmeldung für Kurs ab
		</div>
		<div class="-space-y-px">
			<CourseDatesGroup
					v-model="selected"
					:courseEvent="courseEvent"
					:radio-values="props.eventdates"
					@useProposedDates="emit('useProposedDates', $event)"
			></CourseDatesGroup>
		</div>
	</fieldset>
</template>

<script lang="ts" setup>
import CourseDatesGroup from "./coursedates/CourseDatesGroup.vue"
import {computed, onMounted} from "vue"
import {EventdateInterface} from "@/js/interfaces/eventdate"
import {CartEventInterface} from "@/js/interfaces/event"

const props = defineProps<{
	courseEvent: CartEventInterface
	modelValue?: number | null | undefined | "custom",
	eventdates: EventdateInterface[]
}>()

const emit = defineEmits(["useProposedDates", "update:modelValue"])
onMounted(() => {
	emit("useProposedDates", true)
})

const selected = computed({
	get: () => {
		return props.modelValue ? props.modelValue : null
	},
	set: (value) => {
		emit("update:modelValue", value)
	}
})
</script>

